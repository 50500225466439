<template>
  <form-wizard-create-dishe
    :id="id"
    :dishe="dishe"
    :recordnamedishe="recordnamedishe"
    :recordingredient="recordingredient"
    :recordinfnutri="recordinfnutri"
  />
</template>

<script>
import FormWizardCreateDishe from '../components/FormWizardCreateDishe'

export default {
  inject: ['disheRepository'],
  components: {
    FormWizardCreateDishe,
  },
  data() {
    return {
      dishe: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      recordnamedishe: null,
      recordingredient: null,
      recordinfnutri: null,
    }
  },
  async mounted() {
    const me = this

    if (me.id) {
      const dishe = await me.disheRepository.find(me.id)
      const disheName = {
        accompaniment: dishe.accompaniment != '0',
        image: dishe.image,
        name: dishe.name,
        type_accompaniment: dishe.type_accompaniment,
      }

      me.recordnamedishe = disheName
      me.recordingredient = dishe.ingredients
      me.recordinfnutri = dishe.nutritionalinformation.length > 0
				? JSON.parse(dishe.nutritionalinformation[0].data.nutritional_values)
				: null
    } else {
      me.recordnamedishe = null
      me.recordingredient = null
      me.recordinfnutri = null
    }
  },
}
</script>
<style lang="scss"></style>
